

















































import {Component, Prop, Ref, Vue} from "vue-property-decorator"
import Address from "@/models/Address"
import {getModule} from "vuex-module-decorators"
import DialogModule from "@/store/DialogModule"
import Dialog from "@/models/vue/Dialog"
import AddressService from "@/services/AddressService"
import StringTool from "@/services/tool/StringTool"
import LangModule from "@/store/LangModule"
import PotentialClient from "@/models/PotentialClient"
import PotentialClientService from "@/services/PotentialClientService"

@Component
export default class PotentialClientDialog extends Vue {
	lang: any = getModule(LangModule).lang
	@Ref() readonly form!: HTMLFormElement
	@Prop() newClient!: any
	@Prop({ default: false }) readonly isPotential!: boolean
	potentialClient: PotentialClient = new PotentialClient()
	address: Address = new Address()

	emailRules = [
		(v: string) => v && v.length > 0 ? true : this.lang.emailRequired,
		(v: string) => StringTool.validateEmail(v) ? true : this.lang.emailNoValid
	]
	basicRules = [
		(input: string | undefined) => (input != undefined ? true : this.lang.pleaseFillField),
		(input: string  | undefined) => input != undefined && input.length >= 4 ? true : this.lang.fieldMin4Characters,
		(input: string  | undefined) => input != undefined && input.length <= 256 ? true : this.lang.fieldMax256Characters,
	]
	titleRules = [(v: string) => v && v.length >= 2 ? true : this.lang.fieldMin2Characters]

	closeDialog() {
		this.$emit("close")
	}

	refresh() {
		this.$emit("refresh")
	}

	createClient() {
		if (this.form.validate()) {
			getModule(DialogModule).showDialog(new Dialog(
				 this.lang.warning,
				 this.lang.createClientQuestion,
				 async () => {

					 await PotentialClientService.postPotentialClient(this, this.potentialClient)
					 await this.closeDialog()
					 await this.refresh()
				 }
			))
		}
	}
}
