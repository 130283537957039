

































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import OrderService from "@/services/OrderService";
import Order, {OrderSelect, OrderType} from "@/models/Order";
import Options from "@/models/vue/Options";
import Client from "@/models/Client";
import Company from "@/models/Company";
import CompanyService from "@/services/CompanyService";
import SessionModule from "@/store/SessionModule";
import {getModule} from "vuex-module-decorators";
import LangModule from "@/store/LangModule";
import ClientDialog from "@/components/dialog/ClientDialog.vue";
import ClientService from "@/services/ClientService";
import PotentialClientService from "@/services/PotentialClientService";
import PotentialClientDialog from "@/components/dialog/PotentialClientDialog.vue";
import {AuthorityName} from "@/models/Authority";

@Component({components: {ClientDialog, PotentialClientDialog}})
export default class PotentialClients extends Vue {

	lang: any = getModule(LangModule).lang
	sessionModule: SessionModule = getModule(SessionModule)
	dialog: boolean = false
	loading: boolean = false
	page: number = 1
	pageCount: number = 0
	itemsPerPage: number = 10
	totalItems: number = 0
	search: string = ""
	potentialClients: Client[] = []
	active: boolean = false
	all: boolean = false
	authority?: string = this.sessionModule.session.authorities[0].name

	get headers() {

		let headers = [
			{ text: this.lang.name, value: "name", width: "auto", align: "center" },
			{ text: this.lang.email, value: "email", width: "auto", align: "center" },
			{ text: this.lang.phone, value: "phone", width: "auto", align: "center" },
		]

		if (this.authority != AuthorityName.COMMERCIAL) {
			headers.push({ text: this.lang.createdBy, value: "createdBy", width: "auto", align: "center" })
		}

		return headers
	}

	options: Options = new Options()

	getPotentialClientsCSV() {
		PotentialClientService.getMyPotentialClientCSV(this,this.page - 1, this.itemsPerPage, this.search, true, !this.active)
	}

	created() {
		this.refresh()
	}

	rowClick(client: Client) {
		this.$router.push({path: "/potential-clients/" + client.id})
	}

	refresh() {
		PotentialClientService.getPotentialClients(this, this.potentialClients,this.page - 1, this.itemsPerPage, this.search, true, !this.active)
	}

	openCreateDialog() {
		this.dialog = true
	}

}
